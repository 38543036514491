import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

const SubUser = () => {

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<section className="section-preset">
			<div className="container">
				<div className="row">
					<div className="col-12 p-1">
						<h4 className=""><strong>Sub-user Management</strong></h4>
						<p className="">Create sub-user accounts to easily manage Medichip Membership for your friends and family.</p>
					</div>
				</div>

				<div className="row">
					<div className="col-12 p-1">
						<div className="data-box">
							<p className="mb-0"><strong>Register Sub-user</strong></p>
							<button disabled className="btn setting-button mt-2" onClick={(e) => {
							}}>
								<p className="mb-0"><i className="fa-solid fa-user-plus mr-2"></i><strong>Register</strong></p>
							</button>
						</div>
					</div>
				</div>
			</div>
		</section>
	);

};

export default SubUser;