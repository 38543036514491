import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

// Hooks
import { useAuthContext } from '../../hooks/useAuthContext';

const Earning = () => {

	const { loggedInUser } = useAuthContext();

	const [userData, setUserData] = useState(null);
	const fetchUserData = async () => {
	  const response = await fetch('https://api.medichip.health/user/'+loggedInUser.user.username, {
		method: 'GET',
		headers: {
		  'Content-Type': 'application/json',
		  'Authorization': `Bearer ${loggedInUser.token}`
		}
	  });
	  const json = await response.json();
	  if (response.ok) {
		  if (json.length === 1) {
			// Expect only 1 result
			console.log(json[0]);
			setUserData(json[0]);
		  } else {
			console.log("Invalid user data: More than 1 result.");
		  }
	  }
	};

	useEffect(() => {
		window.scrollTo(0, 0);
		if (loggedInUser) {
			fetchUserData();
		}
	}, []);

	return (
		<section className="section-preset">
			<div className="container">
				<div className="row">
					<div className="col-12 p-1">
						<h4 className=""><strong>My Earnings</strong></h4>
					</div>
				</div>

				<div className="row">
					<div className="col-12 p-1">
                        <div className="data-box mt-2">
							<p className="mb-0"><strong>Total Team Top Up</strong></p>
                            <p className="mb-0"><strong><span className="profit-green">{parseFloat(2000).toFixed(2)}</span> MYR</strong></p>
						</div>
                        <div className="data-box mt-2">
							<p className="mb-0"><strong>Total Earning (Current Month)</strong></p>
                            <p className="mb-0"><strong><span className="profit-green">{parseFloat(20).toFixed(2)}</span> MYR</strong></p>
						</div>
						<div className="data-box mt-2">
							<p className="mb-0"><strong>Bonus Wallet</strong></p>
                            <p className="mb-0"><strong><span className="profit-green">{userData && parseFloat(userData.points).toFixed(2)}</span> MYR</strong></p>
                            <button disabled className="btn setting-button mt-2" onClick={(e) => {
                            }}>
                                <p className="mb-0"><strong>Withdraw</strong></p>
                            </button>
						</div>
					</div>
				</div>


				<div className="row mt-3">
					<div className="col-12 mt-2 px-2">
						<h6 className=""><strong>My Bonuses</strong></h6>
						{/* {userDirectReferrals && userDirectReferrals.length > 0 && userData && userData.total_deposit >= 50 ? ( */}
							<div className="table-container details-table">
								<table className="table borderless">
									<thead>
										<tr>
											{/* <th scope="col" className="py-2"><p className="mb-0" style={{fontSize:"14px"}}>No.</p></th> */}
											<th scope="col" className="py-2"><p className="mb-0" style={{fontSize:"14px"}}>Bonus Details</p></th>
											{/* <th scope="col" className="py-2"><p className="mb-0" style={{fontSize:"14px"}}>Item</p></th>
											<th scope="col" className="py-2"><p className="mb-0" style={{fontSize:"14px"}}>Status</p></th> */}
										</tr>
									</thead>
									<tbody>
										<tr>
										{/* <tr key={index}> */}
											{/* <td className="py-2">
												<p className="mb-0" style={{fontSize:"14px"}}><strong>No.: </strong>#1</p>
												<p className="mb-0" style={{fontSize:"14px"}}><strong>Date: </strong>2024-9-22 15:32H</p>
												<p className="mb-0" style={{fontSize:"14px"}}><strong>User: </strong>yungjien</p>
												<p className="mb-0" style={{fontSize:"14px"}}><strong>Top Up Amount: </strong>200 PV</p>
												<p className="mb-0" style={{fontSize:"14px"}}><strong>Commission: <span className="profit-green">20</span> MYR</strong></p>
												<p className="mb-0" style={{fontSize:"14px"}}><strong>Status: <span className="pending">Processing</span></strong></p>
											</td> */}
											{/* <td className="py-2"><p className="mb-0" style={{fontSize:"14px"}}>1</p></td>
											<td className="py-2"><p className="mb-0" style={{fontSize:"14px"}}>1</p></td> */}
											{/* <td className="py-2"><p className="text-white mb-0" style={{fontSize:"14px"}}>{parseFloat(teamUser.total_deposit).toFixed(2)}</p></td>
											<td className="py-2"><p className="text-white mb-0" style={{fontSize:"14px"}}>{teamUser.total_team_sales > 0 ? parseFloat(teamUser.total_team_sales).toFixed(2) : 0}</p></td>
											<td className="py-2"><p className="text-white mb-0" style={{fontSize:"14px"}}>{teamUser.referral_count}</p></td> */}
										</tr>

									</tbody>
								</table>
							</div>

							{/* <div className="data-box">
								<div className="row">
									<div className="col-12">
										<p className="text-gray mb-0">此账户还未有推荐。</p>
									</div>
								</div>
							</div> */}

						
					</div>
				</div>

				{/* <div className="row mt-3">
					<div className="col-12 mt-2 px-2">
						<h6 className=""><strong>My Withdrawals</strong></h6>

							<div className="table-container details-table">
								<table className="table borderless">
									<thead>
										<tr>
											<th scope="col" className="py-2"><p className="mb-0" style={{fontSize:"14px"}}>No.</p></th>
											<th scope="col" className="py-2"><p className="mb-0" style={{fontSize:"14px"}}>Withdrawal Details</p></th>
											<th scope="col" className="py-2"><p className="mb-0" style={{fontSize:"14px"}}>Item</p></th>
											<th scope="col" className="py-2"><p className="mb-0" style={{fontSize:"14px"}}>Status</p></th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td className="py-2">
												<p className="mb-0" style={{fontSize:"14px"}}><strong>Withdrawal ID: </strong>#001</p>
												<p className="mb-0" style={{fontSize:"14px"}}><strong>Date: </strong>2024-9-22 15:32H</p>
												<p className="mb-0" style={{fontSize:"14px"}}><strong>User: </strong>yungjien</p>
												<p className="mb-0" style={{fontSize:"14px"}}><strong>Amount: <span className="profit-green">20</span> MYR</strong></p>
												<p className="mb-0" style={{fontSize:"14px"}}><strong>Bank Name: </strong>Maybank</p>
												<p className="mb-0" style={{fontSize:"14px"}}><strong>Account Number: </strong>162311884717</p>
												<p className="mb-0" style={{fontSize:"14px"}}><strong>Account Name: </strong>Cheah Yung Jien</p>
												<p className="mb-0" style={{fontSize:"14px"}}><strong>Status: <span className="pending">Processing</span></strong></p>
											</td>
											<td className="py-2"><p className="mb-0" style={{fontSize:"14px"}}>1</p></td>
											<td className="py-2"><p className="mb-0" style={{fontSize:"14px"}}>1</p></td>
											<td className="py-2"><p className="text-white mb-0" style={{fontSize:"14px"}}>{parseFloat(teamUser.total_deposit).toFixed(2)}</p></td>
											<td className="py-2"><p className="text-white mb-0" style={{fontSize:"14px"}}>{teamUser.total_team_sales > 0 ? parseFloat(teamUser.total_team_sales).toFixed(2) : 0}</p></td>
											<td className="py-2"><p className="text-white mb-0" style={{fontSize:"14px"}}>{teamUser.referral_count}</p></td>
										</tr>

									</tbody>
								</table>
							</div>


						
					</div>
				</div> */}

			</div>
		</section>
	);

};

export default Earning;