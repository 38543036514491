import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import Moment from 'moment';
import {CopyToClipboard} from 'react-copy-to-clipboard';

// Hooks
import { useAuthContext } from '../../hooks/useAuthContext';
import { useRecharge } from '../../hooks/useRecharge';

const Wallet = () => {

	const { loggedInUser } = useAuthContext();
	const { submitRecharge, submittedRecharge, selectedRecharge, setSelectedRecharge } = useRecharge();

	const [selectedPackage1, setSelectedPackage1] = useState(false);
	const [selectedPackage2, setSelectedPackage2] = useState(false);
	const [selectedPackage3, setSelectedPackage3] = useState(false);
	const [selectedPackage4, setSelectedPackage4] = useState(false);

	const [paymentMethod, setPaymentMethod] = useState("Bank Transfer");

	const [amount, setAmount] = useState(0);

	const [copiedReferenceID, setCopiedReferenceID] = useState(false);
	const copyReferenceID = () => {    
		setCopiedReferenceID(true);
	  	setTimeout(() => setCopiedReferenceID(false), 2000);
	}

	const [copiedBank, setCopiedBank] = useState(false);
	const copyBankNumber = () => {    
		setCopiedBank(true);
	  	setTimeout(() => setCopiedBank(false), 2000);
	}

	const [userData, setUserData] = useState(null);
	const fetchUserData = async () => {
	  const response = await fetch('https://api.medichip.health/user/'+loggedInUser.user.username, {
		method: 'GET',
		headers: {
		  'Content-Type': 'application/json',
		  'Authorization': `Bearer ${loggedInUser.token}`
		}
	  });
	  const json = await response.json();
	  if (response.ok) {
		  if (json.length === 1) {
			// Expect only 1 result
			console.log(json[0]);
			setUserData(json[0]);
		  } else {
			console.log("Invalid user data: More than 1 result.");
		  }
	  }
	};

	const handleSubmitRecharge = async (e) => {
		e.preventDefault();
		setSelectedRecharge(null);
		if (amount == 0 || paymentMethod == '') {
			return;
		}
		await submitRecharge(loggedInUser.token, loggedInUser.user.username, amount, paymentMethod);
		setSelectedPackage1(false);
		setSelectedPackage2(false);
		setSelectedPackage3(false);
		setSelectedPackage4(false);
		setAmount(0);
		fetchUserData();
		fetchRechargeData();
	};

	const [rechargeData, setRechargeData] = useState(null);
	const fetchRechargeData = async () => {
	  const response = await fetch('https://api.medichip.health/recharge/user/'+loggedInUser.user.username, {
		method: 'GET',
		headers: {
		  'Content-Type': 'application/json',
		  'Authorization': `Bearer ${loggedInUser.token}`
		}
	  });
	  const json = await response.json();
	  if (response.ok) {
		setRechargeData(json);
	  }
	};

	useEffect(() => {
		window.scrollTo(0, 0);
		if (loggedInUser) {
			fetchUserData();
			fetchRechargeData();
		}
	}, []);

	return (
		<>
			<div className="modal fade" id="rechargeModal" tabindex="-1" role="dialog" aria-labelledby="rechargePV" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered" role="document">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="exampleModalLongTitle">Recharge Details</h5>
							<button type="button" className="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true"><i className="fa-solid fa-circle-xmark"></i></span>
							</button>
						</div>
							<div className="modal-body">
								{selectedRecharge != null ? (
									<div className="mb-3">
										<h6 className=""><strong>Reference ID:</strong><br></br>{selectedRecharge._id}</h6>
										<CopyToClipboard 
											text={selectedRecharge._id}
											onCopy={() => copyReferenceID()}>
											<button className="btn setting-button mb-3" onClick={(e) => {
											}}>
												{!copiedReferenceID ? (
													<p className="mb-0"><i className="fa-sharp fa-solid fa-copy mr-2"></i><strong>Copy Reference ID</strong></p>
												):(
													<p className="mb-0"><i className="fa-sharp fa-solid fa-copy mr-2"></i><strong>Copied</strong></p>
												)}
											</button>
										</CopyToClipboard>
										{selectedRecharge.status == "Pending" ? <h6 className="mb-3"><strong>Status: <span className="pending">{selectedRecharge.status}</span></strong></h6> : <></>}
										{selectedRecharge.status == "Completed" ? <h6 className="mb-3"><strong>Status: <span className="profit-green">{selectedRecharge.status}</span></strong></h6> : <></>}
										{selectedRecharge.status == "Cancelled" ? <h6 className="mb-3"><strong>Status: <span className="error">{selectedRecharge.status}</span></strong></h6> : <></>}
										<h6 className="mb-3"><strong>Recharge For:</strong><br></br>{selectedRecharge.username}</h6>
										<h6 className="mb-3"><strong>PV Recharge Amount:</strong><br></br><span className="profit-green">+{selectedRecharge.recharge_amount} PV</span></h6>
										<h6 className="mb-3"><strong>Payment Amount:</strong><br></br>{selectedRecharge.recharge_amount} MYR</h6>
										<h6 className="mb-3"><strong>Payment Method:</strong><br></br>{selectedRecharge.payment_method}</h6>
										{selectedRecharge.status == "Pending" ? (
											<>
												<p className="" style={{fontSize:"13px"}}><strong>Transfer Guideline:</strong><br></br>Kindly make payment to the following company bank account and the PV will be recharged into the receiving wallet once the payment is confirmed. If PV is not received after 1 business day, please contact our customer service team via WhatsApp.</p>
												<div className="data-box">
													<h6 className="mb-2"><strong>Bank Name: </strong>Hong Leong Bank</h6>
													<h6 className="mb-2"><strong>Account No.: </strong>21500080536</h6>
													<h6 className="mb-0"><strong>Account Name: </strong>MEDICHIP HEALTHCARE</h6>
													<CopyToClipboard 
														text={"21500080536"}
														onCopy={() => copyBankNumber()}>
														<button className="btn setting-button mt-2" onClick={(e) => {
														}}>
															{!copiedBank ? (
																<p className="mb-0"><i className="fa-sharp fa-solid fa-copy mr-2"></i><strong>Copy Account No.</strong></p>
															):(
																<p className="mb-0"><i className="fa-sharp fa-solid fa-copy mr-2"></i><strong>Copied</strong></p>
															)}
														</button>
													</CopyToClipboard>
												</div>
											</>
										):(
											<></>
										)}
									</div>
								):(
									<p className="mb-0">Fetching recharge data, please wait...</p>
								)}
							</div>
					</div>
				</div>
			</div>

			<section className="section-preset">
				<div className="container">
					<div className="row">
						<div className="col-12 p-1">
							<h4 className=""><strong>My Wallet</strong></h4>
						</div>
					</div>

					<div className="row">
						<div className="col-12 p-1">
							<div className="data-box mt-2">
								<p className="mb-0"><strong>PV Points</strong></p>
								<p className="mb-0"><strong><span className="profit-green"> {parseFloat(userData && userData.cashWallet).toFixed(2)}</span> PV</strong></p>
							</div>
						</div>
					</div>

					<div className="row mt-3">
						<div className="col-12 p-1">
							<h6 className=""><strong>Choose Recharge Package:</strong></h6>
							<div className="row mx-0">
								<div className="col-6 col-md-3 px-1 mt-2">
									<button className="btn w-100 p-0" onClick={(e) => {
										setSelectedPackage1(true);
										setSelectedPackage2(false);
										setSelectedPackage3(false);
										setSelectedPackage4(false);
										setAmount(200);
									}}>
										<div className={selectedPackage1 ? "data-box text-center selected-package" : "data-box text-center"}>
											<p className="mb-0"><strong>RM200</strong></p>
											<p className="profit-green mb-0"><strong>+200 PV</strong></p>
										</div>
									</button>
								</div>
								<div className="col-6 col-md-3 px-1 mt-2">
									<button className="btn w-100 p-0" onClick={(e) => {
										setSelectedPackage1(false);
										setSelectedPackage2(true);
										setSelectedPackage3(false);
										setSelectedPackage4(false);
										setAmount(500);
									}}>
										<div className={selectedPackage2 ? "data-box text-center selected-package" : "data-box text-center"}>
											<p className="mb-0"><strong>RM500</strong></p>
											<p className="profit-green mb-0"><strong>+500 PV</strong></p>
										</div>
									</button>
								</div>
								<div className="col-6 col-md-3 px-1 mt-2">
									<button className="btn w-100 p-0" onClick={(e) => {
										setSelectedPackage1(false);
										setSelectedPackage2(false);
										setSelectedPackage3(true);
										setSelectedPackage4(false);
										setAmount(1000);
									}}>
										<div className={selectedPackage3 ? "data-box text-center selected-package" : "data-box text-center"}>
											<p className="mb-0"><strong>RM1000</strong></p>
											<p className="profit-green mb-0"><strong>+1000 PV</strong></p>
										</div>
									</button>
								</div>
								<div className="col-6 col-md-3 px-1 mt-2">
									<button className="btn w-100 p-0" onClick={(e) => {
										setSelectedPackage1(false);
										setSelectedPackage2(false);
										setSelectedPackage3(false);
										setSelectedPackage4(true);
										setAmount(3000);
									}}>
										<div className={selectedPackage4 ? "data-box text-center selected-package" : "data-box text-center"}>
											<p className="mb-0"><strong>RM3000</strong></p>
											<p className="profit-green mb-0"><strong>+3000 PV</strong></p>
										</div>
									</button>
								</div>
							</div>
						</div>
					</div>

					<div className="row mt-3">
						<div className="col-12 p-1">
							<h6 className=""><strong>Choose Payment Method:</strong></h6>
							<div className="row mx-0">
								<div className="col-6 col-md-3 px-1 mt-2">
									<button className="btn w-100 p-0" onClick={(e) => {
									}}>
										<div className={paymentMethod == "Bank Transfer" ? "data-box text-center selected-package" : "data-box text-center"}>
											<p className="mb-0"><strong>Bank Transfer</strong></p>
										</div>
									</button>
								</div>
								<div className="col-6 col-md-3 px-1 mt-2">
									<button disabled className="btn w-100 p-0" onClick={(e) => {
									}}>
										<div className="data-box text-center">
											<p className="mb-0"><strong>Online Payment</strong></p>
										</div>
									</button>
								</div>
							</div>
							<p className="mt-3 mb-0" style={{fontSize:"12px"}}><strong>Please take note:</strong></p>
							<p className="mb-0" style={{fontSize:"12px"}}>All bank transfer recharge transactions will be verified within 24 hours. If you did not receive your PV recharge after 24 hours, please contact our customer support team. We thank you for your patience and understanding.</p>
						</div>
					</div>
					
					{amount > 0 ? (
						<div className="row mt-3">
							<div className="col-12 p-1">
								<h6 className=""><strong>Total Payment Amount:</strong><br></br>{parseFloat(amount).toFixed(2)} MYR</h6>
								<h6 className="mt-3"><strong>Total PV Receive: <span className="profit-green">+{parseFloat(amount).toFixed(0)}</span></strong></h6>
								<form onSubmit={handleSubmitRecharge}>
									<button className="btn main-button mt-3" type="submit" data-toggle="modal" data-target="#rechargeModal" onClick={(e) => {

									}}>
										<p className="mb-0"><strong>Confirm Recharge</strong></p>
									</button>
								</form>

							</div>
						</div>
					):(
						<></>
					)}

					<div className="row mt-5">
						<div className="col-12 mt-2 px-2">
						<h6 className=""><strong>Recharge History</strong></h6>
							{rechargeData && rechargeData.length > 0 ? (
								<div className="table-container details-table">
									<table className="table borderless">
										<thead>
											<tr>
												<th scope="col" className="py-2"><p className="mb-0" style={{fontSize:"14px"}}>Recharge Details</p></th>
											</tr>
										</thead>
										<tbody>
											{rechargeData && rechargeData.map((recharge, index) => (
												<>
													<tr key={index}>
														<td className="py-2">
															<p className="mb-0" style={{fontSize:"14px"}}><strong>Recharge ID: </strong>{recharge._id}</p>
															<p className="mb-0" style={{fontSize:"14px"}}><strong>Time: </strong>{Moment(recharge.createdAt).format('YYYY/MM/DD HH:mm')}H</p>
															<p className="mb-0" style={{fontSize:"14px"}}><strong>Recharge Amount: </strong>{parseFloat(recharge.recharge_amount).toFixed(2)}</p>
															<p className="mb-0" style={{fontSize:"14px"}}><strong>Payment Method: </strong>{recharge.payment_method}</p>
															{recharge.status == "Pending" ? <p className="mb-0" style={{fontSize:"14px"}}><strong>Status: <span className="pending">Pending</span></strong></p> : ""}
															{recharge.status == "Completed" ? <p className="mb-0" style={{fontSize:"14px"}}><strong>Status: <span className="profit-green">Completed</span></strong></p> : ""}
															<p className="mb-0" style={{fontSize:"14px"}}><strong>Remark: </strong>{recharge.remark != "" ? recharge.remark : "-" }</p>
															{recharge.status == "Pending" ? 
																<button className="btn main-button mt-2" data-toggle="modal" data-target="#rechargeModal" onClick={(e) => {
																	setSelectedRecharge(recharge);
																}}>
																	<p className="mb-0"><strong>View Details</strong></p>
																</button>
																:
																<></>
															}
														</td>
													</tr>
													<hr></hr>
												</>
											))}
											<tr>
												<td className="py-0">
													<p className="mb-0"><strong>Total Record: </strong>{rechargeData.length}</p>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							):(
								<div className="data-box">
									<p className="mb-0" style={{fontSize:"14px"}}>No recharge record is found for this user.</p>
								</div>
							)}
						</div>
					</div>

				</div>
			</section>
		</>
	);

};

export default Wallet;