// Dependencies
import React, { useEffect, useState } from 'react';
import { Sidebar, Menu, MenuItem, SubMenu, menuClasses } from 'react-pro-sidebar';
import { BrowserRouter as Router, Route, Routes, Navigate, NavLink } from 'react-router-dom';

// Pages
import HomePage from './pages/home/home.js';
import LoginPage from './pages/login/login.js';
import RegisterPage from './pages/register/register.js';
import ProfilePage from './pages/profile/profile.js';
import WalletPage from './pages/wallet/wallet.js';
import AppointmentPage from './pages/appointment/appointment.js';
import OrderPage from './pages/order/order.js';
import ReferralPage from './pages/referral/referral.js';
import ShopPage from './pages/shop/shop.js';
import ProductPage from './pages/product/product.js';
import EarningPage from './pages/earning/earning.js';
import SubUserPage from './pages/sub-user/sub-user.js';

// Hooks
import { useLogout } from './hooks/useLogout';
import { useAuthContext } from './hooks/useAuthContext';

// Content
import LogoWord from './assets/img/logo-word.png';
import FacebookIcon from './assets/img/facebook.png';
import TwitterIcon from './assets/img/twitter.png';
import InstagramIcon from './assets/img/instagram.png';
import YoutubeIcon from './assets/img/youtube.png';

function App() {

  const { loggedInUser } = useAuthContext();

  const [toggled, setToggled] = useState(false);

  const { logout } = useLogout();
  const handleLogout = () => {
    logout();
    window.location.href= "/";
  }

  const [userData, setUserData] = useState(null);
  const fetchUserData = async () => {
    const response = await fetch('https://api.medichip.health/user/'+loggedInUser.user.username, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${loggedInUser.token}`
      }
    });
    const json = await response.json();
    if (response.ok) {
        if (json.length === 1) {
          // Expect only 1 result
          setUserData(json[0]);
        } else {
          console.log("Invalid user data: More than 1 result.");
        }
    }
	};


  useEffect(() => {
    // getData();
    window.scrollTo(0, 0);
    if (loggedInUser) {
      fetchUserData();
    }
  }, []);


  return (
    <>
      <Router>

        {/* Navbar */}
        <nav className="">
          <div className="container-fluid">
            <div className="header navbar row">
              <div className="col-6 col-md-5 p-0">
                <NavLink to="/">
                  <img src={LogoWord} className="header-logo" alt="logotext"/>
                </NavLink>
              </div>
              <div className="col-6 col-md-7 p-0 text-right">
                {loggedInUser == null ? (
                  <NavLink to="/login">
                    <button className="btn main-button" onClick={(e) => {
                    }}>
                      <p className="mb-0"><strong><i className="fa-solid fa-user mr-2"></i>Login</strong></p>
                    </button>
                  </NavLink>
                ):(
                  <button className="btn" onClick={(e) => {
                    // window.scrollTo(0, 0);
                    setToggled(!toggled);
                  }}>
                    <p className="menu-icon mb-0"><strong><i className="fa-sharp fa-solid fa-bars"></i></strong></p>
                  </button>
                )}
              </div>
            </div>
          </div>
        </nav>

        <div className="d-flex">

          <Sidebar
            transitionDuration={600}
            width="260px"
            backgroundColor="#000"
            breakPoint="all"
            onBackdropClick={() => setToggled(false)}
            toggled={toggled}
            rtl="true"
            rootStyles={{
              height: '100vh',
              zIndex: '110',
              border: 'none'
            }}
          >

            <Menu
              renderExpandIcon={({ open }) => 
                <span className="collapse-icon">
                  {open ? <i className="fa-duotone fa-caret-down mt-2"></i> : <i className="fa-duotone fa-caret-right mt-2"></i>}
                </span>
              }
              rootStyles={{
                ['.' + menuClasses.button]: {
                  backgroundColor: '#2e2e2e',
                  color: '#DCDCDC',
                  '&:hover': {
                    backgroundColor: '#00d3b0',
                  },
                },
                ['.active']: {
                  backgroundColor: '#00d3b0',
                }
              }}
            >

              <MenuItem 
                icon={<span className="side-menu-icon"><i className="fa-solid fa-user"></i></span>}
                component={<NavLink to="/profile" activeClassName={['menu-item'].active}/>}
              >
                <p className="side-menu-text">My Profile</p>
              </MenuItem>

              <MenuItem 
                icon={<span className="side-menu-icon"><i className="fa-solid fa-wallet"></i></span>}
                component={<NavLink to="/wallet" activeClassName={['menu-item'].active}/>}
              > 
                <p className="side-menu-text">My Wallet</p>
              </MenuItem>

              <MenuItem 
                icon={<span className="side-menu-icon"><i className="fa-solid fa-hand-holding-circle-dollar"></i></span>}
                component={<NavLink to="/earning" activeClassName={['menu-item'].active}/>}
              > 
                <p className="side-menu-text">My Earnings</p>
              </MenuItem>

              {/* <MenuItem 
                icon={<span className="side-menu-icon"><i className="fa-sharp fa-solid fa-bag-shopping"></i></span>}
                component={<NavLink to="/order" activeClassName={['menu-item'].active}/>}
              > 
                <p className="side-menu-text">My Orders</p>
              </MenuItem> */}

              <MenuItem 
                icon={<span className="side-menu-icon"><i className="fa-solid fa-clipboard-list-check"></i></span>}
                component={<NavLink to="/appointment" activeClassName={['menu-item'].active}/>}
              > 
                <p className="side-menu-text">Appointments</p>
              </MenuItem>

              <MenuItem 
                icon={<span className="side-menu-icon"><i className="fa-solid fa-users-medical"></i></span>}
                component={<NavLink to="/referral" activeClassName={['menu-item'].active}/>}
                // onClick={window.scrollTo(0, 0)}
              >
                <p className="side-menu-text">Referral Program</p>
              </MenuItem>

              <MenuItem 
                icon={<span className="side-menu-icon"><i className="fa-solid fa-user-plus"></i></span>}
                component={<NavLink to="/sub-user" activeClassName={['menu-item'].active}/>}
                // onClick={window.scrollTo(0, 0)}
              >
                <p className="side-menu-text">Sub-user Management</p>
              </MenuItem>

              <MenuItem
                className="menu-item"
                icon={<span className="side-menu-icon"><i className="fa-solid fa-right-from-bracket"></i></span>}
                onClick={handleLogout}
              > 
                <p className="side-menu-text">Logout</p>
              </MenuItem>

            </Menu>

          </Sidebar>

          <div className="main-content">

            <Routes>
              <Route path="/" element={<HomePage/>}/>
              <Route path="/login" element={!loggedInUser ? <LoginPage/> : <Navigate to="/"/>}/>
              <Route path="/register" element={!loggedInUser ? <RegisterPage/> : <Navigate to="/"/>}/>
              <Route path="/profile" element={loggedInUser ? <ProfilePage/> : <Navigate to="/login"/>}/>
              <Route path="/wallet" element={loggedInUser ? <WalletPage/> : <Navigate to="/login"/>}/>
              <Route path="/appointment" element={loggedInUser ? <AppointmentPage/> : <Navigate to="/login"/>}/>
              <Route path="/order" element={loggedInUser ? <OrderPage/> : <Navigate to="/login" />}/>
              <Route path="/referral" element={loggedInUser ? <ReferralPage/> : <Navigate to="/login"/>}/>
              {/* <Route path="/shop" element={<ShopPage/>}/> */}
              <Route path="/earning" element={loggedInUser ? <EarningPage/> : <Navigate to="/login"/>}/>
              <Route path="/sub-user" element={loggedInUser ? <SubUserPage/> : <Navigate to="/login"/>}/>
            </Routes>

            <footer>
              <div className="footer-container">
                <h3 className="text-white">Follow us on social media:</h3>
                <div className="socials-icons">
                    <button className="btn social-button" onClick={(e) => {
                      window.open("https://www.facebook.com/profile.php?id=61564908462551", "_blank");
                    }}>
                      <span className="footer-icon"><i className="fa-brands fa-square-facebook"></i></span>
                    </button>

                    <button className="btn social-button" onClick={(e) => {
                      window.open("https://www.instagram.com/medichiphealthcare", "_blank");
                    }}>
                      <span className="footer-icon"><i className="fa-brands fa-instagram"></i></span>
                    </button>

                    <button className="btn social-button" onClick={(e) => {
                      window.open("http://wa.me/60146868574", "_blank");
                    }}>
                      <span className="footer-icon"><i className="fa-brands fa-whatsapp"></i></span>
                    </button>
                    

                </div>
              </div>
              <div className="footer-container text-center">
                <p className="text-white mt-3" style={{fontSize:"20px"}}><strong>Email: </strong><a className="text-white" href="mailto:medichiphealthcare@outlook.com">medichiphealthcare@outlook.com</a></p>
              </div>
            </footer>
          </div>

        </div>

      </Router>
    </>
  );
}

export default App;