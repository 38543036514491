import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

// Hooks
import { useAuthContext } from '../../hooks/useAuthContext';

const Profile = () => {

	const { loggedInUser } = useAuthContext();

	const [userData, setUserData] = useState(null);
	const fetchUserData = async () => {
	  const response = await fetch('https://api.medichip.health/user/'+loggedInUser.user.username, {
		method: 'GET',
		headers: {
		  'Content-Type': 'application/json',
		  'Authorization': `Bearer ${loggedInUser.token}`
		}
	  });
	  const json = await response.json();
	  if (response.ok) {
		  if (json.length === 1) {
			// Expect only 1 result
			console.log(json[0]);
			setUserData(json[0]);
		  } else {
			console.log("Invalid user data: More than 1 result.");
		  }
	  }
	};

	useEffect(() => {
		window.scrollTo(0, 0);
		console.log(loggedInUser);
		if (loggedInUser) {
			fetchUserData();
		}
	}, []);

	return (
		<>
			{/* Change Password */}
			{/* <div className="modal fade" id="changePasswordModal" tabindex="-1" role="dialog" aria-labelledby="changePassword" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered" role="document">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="exampleModalLongTitle">Change Password</h5>
							<button type="button" className="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true"><i className="fa-solid fa-circle-xmark"></i></span>
							</button>
						</div>
						{!actionSuccess ? (
							<div className="modal-body">
								{selectedUser != null ? (
									<form className="w-100" onSubmit={changePassword}>
										<div className="mb-2">
											<label htmlFor="newPW">
												<strong>New Password</strong>
											</label>
											<input
												type="password"
												placeholder='Enter new password'
												autoComplete='off'
												name='newPW'
												className='form-control'
												onChange={(e) => setNewPassword(e.target.value)}
											/>
										</div>
										<button disabled={isLoading} type="submit" className="btn setting-button w-100">
											<p className="text-white mb-0">Confirm</p>
										</button>
										{error && <div className="error">{error}</div>}
									</form>
								):(
									<p className="error mb-0">Failed to fetch user data. Please refresh page.</p>
								)}
							</div>
						):(
							<div className="modal-body">
								<div className="text-center">
									<span className="success-icon"><i className="fa-solid fa-circle-check"></i></span>
									<h5 className="">Password Change Success</h5>
								</div>
							</div>
						)}
					</div>
				</div>
			</div> */}

			<section className="section-preset">
				<div className="container">
					<div className="row">
						<div className="col-12 p-1">
							<h4 className=""><strong>My Profile</strong></h4>
						</div>
					</div>

					<div className="row">
						<div className="col-12 p-1">
							<div className="data-box">
								<p className="mb-0"><strong>Username:</strong> {userData && userData.username}</p>
								<p className="mb-0"><strong>Rank:</strong> M{userData && userData.rank}</p>
								<p className="mb-0"><strong>Referrer:</strong> {userData && userData.referrer}</p>
							</div>
						</div>

						<div className="col-12 mt-3 p-1">
							<h6 className=""><strong>Know Your Customer (KYC)</strong></h6>
							<div className="data-box">
								<p className="error mb-0"><strong>KYC Required:</strong></p>
								<p className="mb-0">Please complete the KYC verification process for us to better understand our customers.</p>

								{/* <p className="mb-0"><strong>NRIC:</strong> {userData && userData.email ? userData.email : "-"}</p>
								<p className="mb-0"><strong>Full Name:</strong> {userData && userData.phone ? userData.phone : "Not Set"}</p>
								<p className="mb-0"><strong>Birthday:</strong> {userData && userData.dob ? userData.dob : "Not Set"}</p> */}
								<button disabled className="btn setting-button mt-1" onClick={(e) => {
								}}>
									<p className="mb-0"><strong><i className="fa-solid fa-address-card mr-2"></i>Start KYC</strong></p>
								</button>
							</div>
						</div>

						<div className="col-12 mt-3 p-1">
							<h6 className=""><strong>Personal Details</strong></h6>
							<div className="data-box">
								<p className="mb-0"><strong>Email:</strong> {userData && userData.email ? userData.email : "-"}</p>
								<p className="mb-0"><strong>Phone No.:</strong> {userData && userData.phone ? userData.phone : "Not Set"}</p>
								<p className="mb-0"><strong>Address:</strong><br></br>{userData && userData.address ? userData.address : "Not Set"}</p>
								<button disabled className="btn setting-button mt-1" onClick={(e) => {
								}}>
									<p className="mb-0"><strong><i className="fa-sharp fa-solid fa-pen-to-square mr-2"></i>Edit</strong></p>
								</button>
							</div>
						</div>

						<div className="col-12 mt-3 p-1">
							<h6 className=""><strong>Bank Details</strong></h6>
							<div className="data-box">
								<p className="mb-0"><strong>Bank:</strong> {userData && userData.bankName ? userData.bankName : "Not Set"}</p>
								<p className="mb-0"><strong>Account Number:</strong> {userData && userData.accNumber ? userData.accNumber : "Not Set"}</p>
								<p className="mb-0"><strong>Account Name:</strong> {userData && userData.accName ? userData.accName : "Not Set"}</p>
								<button disabled className="btn setting-button mt-1" onClick={(e) => {
								}}>
									<p className="mb-0"><strong><i className="fa-sharp fa-solid fa-pen-to-square mr-2"></i>Edit</strong></p>
								</button>
							</div>
						</div>

						<div className="col-12 mt-3 p-1">
							<h6 className=""><strong>Account Security</strong></h6>
							<div className="data-box">
								<p className="mb-0"><strong>Password:</strong> ******</p>
								<button disabled className="btn setting-button mt-1" onClick={(e) => {
								}}>
									<p className="mb-0"><strong><i className="fa-sharp fa-solid fa-pen-to-square mr-2"></i>Edit</strong></p>
								</button>
							</div>
						</div>

					</div>
				</div>
			</section>
		</>
	);

};

export default Profile;