import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import Moment from 'moment';

import './appointment.css'

// Hooks
import { useAuthContext } from '../../hooks/useAuthContext';
import { useBooking } from '../../hooks/useBooking';

const Appointment = () => {

	const { loggedInUser } = useAuthContext();
	const { submitBooking } = useBooking();

	const [selectedDate, setSelectedDate] = useState('');
	const [selectedTime, setSelectedTime] = useState('');
	const [selectedUnit, setSelectedUnit] = useState(''); 
	const [bookedSlots, setBookedSlots] = useState([]); 
	const [timeOptions, setTimeOptions] = useState([]);
	const [currentDate] = useState(new Date());
	const [openDropdown, setOpenDropdown] = useState('');

	const units = ['HQ01', 'HQ02', 'HQ03'];
	const branch = 'MedichipHQ';

	const [userBookingData, setUserBookingData] = useState(null);
	const fetchUserBookingData = async () => {
		const response = await fetch('https://api.medichip.health/appointment/user/'+loggedInUser.user.username, {
			method: 'GET',
			headers: {
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${loggedInUser.token}`
			}
		});
		const json = await response.json();
		if (response.ok) {
			setUserBookingData(json);
			console.log(json);
		}
	};


	const timeSlots = {
		weekday: [
		  '08:00 - 08:30', '08:30 - 09:00', '09:00 - 09:30', '09:30 - 10:00',
		  '10:00 - 10:30', '10:30 - 11:00', '11:00 - 11:30', '11:30 - 12:00',
		  '12:00 - 12:30', '12:30 - 13:00', '13:00 - 13:30', '13:30 - 14:00',
		  '14:00 - 14:30', '14:30 - 15:00', '15:00 - 15:30', '15:30 - 16:00',
		  '16:00 - 16:30'
		],
		weekend: [
		  '08:00 - 08:30', '08:30 - 09:00', '09:00 - 09:30', '09:30 - 10:00',
		  '10:00 - 10:30', '10:30 - 11:00', '11:00 - 11:30', '11:30 - 12:00',
		  '12:00 - 12:30'
		]
	};

	const handleDateChange = (e) => {
		setSelectedDate(e.target.value);
	};
	
	const handleTimeSelect = (time, isUnavailable) => {
		if (!isUnavailable) {
			setSelectedTime(time);
		}
	};

	const handleUnitSelect = (unit, isUnavailable) => {
		if (!isUnavailable) {
		setSelectedUnit(unit);
		}
	};

	const isFullyBookedTimeslot = (time) => {
    const bookedForTime = bookedSlots.filter(booking => booking.date === selectedDate && booking.time === time);
		return bookedForTime.length === units.length;
	};

	const isUnitUnavailableForTimeslot = (unit) => {
		return bookedSlots.some(booking => booking.date === selectedDate && booking.time === selectedTime && booking.unit === unit);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (!selectedDate || !selectedTime || !selectedUnit) {
			alert("Please select a date, time, and unit.");
		} else {
			const newBooking = { date: selectedDate, time: selectedTime, unit: selectedUnit };
			setBookedSlots([...bookedSlots, newBooking]);

			await submitBooking(loggedInUser.token, loggedInUser.user.username, selectedUnit, selectedDate, selectedTime);

			alert(`Booking confirmed for ${selectedTime} on ${selectedDate} for ${selectedUnit} at ${branch}`);
			setSelectedDate('');
			setSelectedTime('');
			setSelectedUnit('');
		}
	};

	const isPastTimeSlot = (slot) => {
		const [slotHour] = slot.split(':').map(Number);
		const currentHour = currentDate.getHours();
		return slotHour < currentHour;
	};

	useEffect(() => {
		if (selectedDate) {
		const selectedDay = new Date(selectedDate).getDay();
		const todayDate = new Date().toISOString().split('T')[0];
		let availableSlots = selectedDay === 6 ? timeSlots.weekend : timeSlots.weekday;

		if (selectedDate === todayDate) {
			availableSlots = availableSlots.filter(slot => !isPastTimeSlot(slot));
		}

		if (selectedDay === 0) {
			alert("We are closed on Sundays. Please select another date.");
			setSelectedDate('');
			return;
		}
		setTimeOptions(availableSlots);
		}
	}, [selectedDate]);

	useEffect(() => {
		if (selectedTime) {
		const available = units.filter(unit => !isUnitUnavailableForTimeslot(unit));
		if (available.length === 0) {
			alert("No units available for this timeslot.");
			setSelectedTime(''); 
		}
		}
	}, [selectedTime, bookedSlots]);
	

	useEffect(() => {
		window.scrollTo(0, 0);
		fetchUserBookingData();
	}, []);

	return (
		<section className="section-preset">
			<div className="container">
				<div className="row">
					<div className="col-12 p-1">
						<h4 className=""><strong>Book Appointment</strong></h4>
					</div>
				</div>

				<div className="row">
					<div className="col-12 p-1">
						<div className="data-box">
							<form className="booking" onSubmit={handleSubmit}>
								<input
									type="date"
									id="date"
									value={selectedDate}
									onChange={handleDateChange}
									min={new Date().toISOString().split('T')[0]}
									className="form-control"
								></input>
								<div className="select-time">
									<CustomSelect
										options={timeOptions}
										selectedOption={selectedTime}
										onSelect={handleTimeSelect}
										isDisabled={!selectedDate}
										isUnavailable={isFullyBookedTimeslot}
										showFullyBookedLabel={true}
										placeholder="Select a timeslot"
										openDropdown={openDropdown}
										setOpenDropdown={setOpenDropdown}
										dropdownId="timeslot"
										notifyUser={() => {
										if (!selectedDate) {
											alert('Please select a date first!');
										} else if (!selectedUnit) {
											alert('Please select a unit first!');
										}
										}}
									/>
                				</div>
								<div className="select-unit">
									<CustomSelect
										options={units}
										selectedOption={selectedUnit}
										onSelect={handleUnitSelect}
										isDisabled={!selectedDate}
										isUnavailable={isUnitUnavailableForTimeslot}
										showFullyBookedLabel={false}
										placeholder="Select a unit"
										openDropdown={openDropdown}
										setOpenDropdown={setOpenDropdown} 
										dropdownId="unit"
										notifyUser={() => {
										if (!selectedDate) {
											alert('Please select a date first!');
										}
										}}
									/>
								</div>
								<button className="btn setting-button mt-2" onClick={(e) => {
									console.log(selectedDate, selectedTime, selectedUnit);
								}}>
									<p className="mb-0"><strong>Book Now</strong></p>
								</button>
							</form>
						</div>
					</div>
				</div>



				<div className="row mt-3">
					<div className="col-12 mt-2 px-2">
						<h6 className=""><strong>Appointment History</strong></h6>

						{userBookingData && userBookingData.length > 0 ? (
							<div className="table-container details-table">
								<table className="table borderless">
									<thead>
										<tr>
											<th scope="col" className="py-2"><p className="mb-0" style={{fontSize:"14px"}}>Appointment Details</p></th>
										</tr>
									</thead>
									<tbody>
									{userBookingData && userBookingData.map((appointment, index) => (
										<>
											<tr key={index}>
												<td className="py-2">
													<p className="mb-0" style={{fontSize:"14px"}}><strong>ID: </strong>{appointment._id}</p>
													{appointment.status == "Appointment Confirmed" ? <p className="mb-0" style={{fontSize:"14px"}}><strong>Status: <span className="pending">Appointment Confirmed</span></strong></p> : <></>}
													{appointment.status == "Completed" ? <p className="mb-0" style={{fontSize:"14px"}}><strong>Status: <span className="profit-green">Completed</span></strong></p> : <></>}
													{appointment.status == "Cancelled" ? <p className="mb-0" style={{fontSize:"14px"}}><strong>Status: <span className="error">Cancelled</span></strong></p> : <></>}
													<p className="mb-0" style={{fontSize:"14px"}}><strong>User: </strong>{appointment.username}</p>
													<p className="mb-0" style={{fontSize:"14px"}}><strong>Date: </strong>{Moment(appointment.day).format('YYYY-MM-DD')}</p>
													<p className="mb-0" style={{fontSize:"14px"}}><strong>Time Slot: </strong>{appointment.time}</p>
													<p className="mb-0" style={{fontSize:"14px"}}><strong>Unit: </strong>{appointment.unit}</p>
													{appointment.points_onhold > 0 ? <p className="mb-0" style={{fontSize:"14px"}}><strong>Fee: </strong>{appointment.points_onhold} PV (On-hold)</p> : <></>}
													{appointment.points_spent > 0 ? <p className="mb-0" style={{fontSize:"14px"}}><strong>Fee: </strong>{appointment.points_onhold} PV (Paid)</p> : <></>}
													<p className="mb-0" style={{fontSize:"14px"}}><strong>Remark: </strong>{appointment.remark != "" ?  appointment.remark : "-"}</p>
													
												</td>
											</tr>
											<hr></hr>
										</>
									))}
									<tr>
										<td className="py-0">
											<p className="mb-0"><strong>Total Record: </strong>{userBookingData.length}</p>
										</td>
									</tr>
									</tbody>
								</table>
							</div>
						):(
							<div className="data-box">
								<p className="mt-1 mb-0" style={{fontSize:"14px"}}>No appointment history.</p>
							</div>
						)}

					</div>
				</div>

			</div>
		</section>
	);

};

const CustomSelect = ({ 
	options,
	selectedOption,
	onSelect,
	isDisabled,
	placeholder, 
	notifyUser,
	isUnavailable,
	showFullyBookedLabel,
	openDropdown,
	setOpenDropdown,
	dropdownId }) => {

	const isOpen = openDropdown === dropdownId;
	const handleSelect = (option) => {
		const unavailable = isUnavailable(option);
		onSelect(option, unavailable);
		if (!unavailable) {
			setOpenDropdown('');
		}
	};

	return (
		<div className="custom-select-container">
			<div
				className={`custom-select-header ${isDisabled ? 'disabled' : ''}`}
				onClick={() => {
				if (!isDisabled) {
					setOpenDropdown(isOpen ? '' : dropdownId);
				} else {
					notifyUser();
				}
				}}
			>
				{selectedOption || placeholder}
				<span className="arrow">{isOpen && !isDisabled ? '▲' : '▼'}</span>
			</div>
			{isOpen && !isDisabled && (
				<ul className="custom-select-dropdown">
					{options.map((option) => {            
						const unavailable = isUnavailable(option);
						return (
							<li
								key={option}
								className={`custom-select-option ${unavailable ? 'unavailable' : ''}`}
								onClick={() => handleSelect(option)}
							>
								<span style={{ textDecoration: unavailable ? 'line-through' : 'none', marginRight:'5px' }}>
									{option} 
								</span>
								<span>
									{unavailable && showFullyBookedLabel ? '(fully booked)' : ''}
								</span>
							</li>
						);
					})}
				</ul>
			)}
		</div>
	);
};

export default Appointment;