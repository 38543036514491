import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, NavLink } from 'react-router-dom';
import './home.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Pagination, Navigation, FreeMode } from 'swiper';
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'


import Logo from '../../assets/img/logo.png'
import Banner1 from '../../assets/img/banner-1.png'
import MedichipImg from '../../assets/img/medichip-img.png'
import MedichipBanner from '../../assets/img/medichip-banner.png'
import Wristband from '../../assets/img/wristband.png'
import PurchaseButton from '../../assets/img/purchase-button.png'
import BioChamber from '../../assets/img/bioresonance.png'
import Star from '../../assets/img/star.png'

import ListIcon from '../../assets/img/list-style.png'

SwiperCore.use([Autoplay, Pagination, Navigation, FreeMode]);

const Login = () => {

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<section>
			{/* Home Banner */}
			<div className="banner-slider">
				<div className="banner-slider-content">
					<img src={Banner1} alt="Picture of a family waving hands" />
				</div>

				<div className="appointment-info">
					<h3>
						Unlock the Power of Bioresonance<br/>for Healthier Cells
					</h3>
					<div className="service-info">
						<h5 className="text-white">Experience cutting-edge technology that promotes cellular rejuvenation and long-lasting wellness.</h5>
						<div className="service-info-content">
						
							<div className="working-time-1 text-white">
								<h6 className="font-weight-bold">Working Time</h6>
								<div>
									<p>Monday - Friday &nbsp;&nbsp;&#9135;&nbsp;&nbsp; 8:00 - 17:00</p>
									<p>Saturday&nbsp;&nbsp;&#9135;&nbsp;&nbsp; 8:00 - 13:00</p>
								</div>
							</div>
							<p className="text-white mt-3" style={{fontSize:"20px"}}><strong>Email: </strong><a className="text-white" href="mailto:medichiphealthcare@outlook.com">medichiphealthcare@outlook.com</a></p>
							<div className="d-flex flex-column buttons">
								<NavLink to="/appointment" className="appointment-button">
									<button className="btn main-button book-button mt-2" onClick={(e) => {
									}}>
										<p className="mb-0"><strong>Book Our Free Consultation</strong></p>
									</button>
								</NavLink>

								<button className="btn main-button book-button mt-2" onClick={(e) => {
									window.open("http://wa.me/60146868574", "_blank");
								}}>
									<p className="mb-0"><strong>Contact Us On WhatsApp</strong></p>
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="services-ratings">
				<div>
					<h3>6,500+</h3>
					<h6>Satisfied<br/>Clients</h6>
				</div>
				<div>
					<h3>8+</h3>
					<h6>Years of<br/>Experience</h6>
				</div>
				<div>
					<h3>92%</h3>
					<h6>Repeat<br/>Customers</h6>
				</div>
				<div>
					<h3>4.8/5</h3>
					<h6>Average<br/>Review</h6>
				</div>
			</div>

			<img src="" alt="" />

			{/* Who are we */}
			<div className="medichip w-100">
				<div className="medichip-container">
					<div className="medichip-img">
						<img src={MedichipImg} alt="A person holding a chip" />
					</div>
					<div className="medichip-content">
						<h5 className="text-white">WHO WE ARE</h5>
						<h2>MediChip</h2>
						<p>
						At Medichip Healthcare, we combine bioresonance technology, nanowave innovations, 
						and far-infrared therapy to promote optimal cellular health. Our mission is to help 
						individuals achieve lasting wellness by revitalizing their cells using science-backed, 
						advanced technology.
						</p>
					</div>
				</div>
				<img src={MedichipBanner} alt="Futuristic touchscreen with a person's finger operating it" />
			</div>

			<img src="" alt="" />
			<img src="" alt="" />

			{/* What we do */}
			<div className="what-we-do">
				<img src="" alt="" />
				<div className="what-we-do-content">
					<div className="title-bg">
						<h2>
							Our
							<br/>
							<span>
								Revolutionary
								<br/>
								Technology
							</span>
						</h2>
						<p>
						Medichip Healthcare uses proprietary technology based on bioresonance, nanowave, 
						and biophoton innovations to improve your health at the cellular level
						</p>
					</div>
				</div>

				<div className="technology">
					<div className="technology-cards">
						<div className="technology-info">
							<h6>Bioresonance Technology</h6>
							<p>Coded electromagnetic waves to activate cellular health.</p>
						</div>
					</div>
					<div className="technology-cards">
						<div className="technology-info">
							<h6>Nanowave Technology</h6>
							<p>Accelerates cell regeneration.</p>
						</div>
					</div>
					<div className="technology-cards">
						<div className="technology-info">
							<h6>Far-infrared Therapy</h6>
							<p>Enhances circulation and detoxification.</p>
						</div>
					</div>
					<div className="technology-cards">
						<div className="technology-info">
							<h6>Biophoton Technology</h6>
							<p>Energizes and optimizes cellular function.</p>
						</div>
					</div>
				</div>

			</div>

			{/* Bioresonance chamber */}
			<div className="biochamber-container">
				<div className="biochamber-img">
					<img src={BioChamber} alt="" />
				</div>
				<div className="biochamber-info">
					<h2>
					BioResonance
					<br/>
					<span>Chamber</span>
					</h2>
					<div className="biochamber-info-content">
						<div className="info-list">
							<ul className="font-italic font-weight-bold">
								<li><img src={ListIcon}/>Non-invasive Technology</li>
								<li><img src={ListIcon}/>Anti-ageing</li>
								<li><img src={ListIcon}/>Alzheimer-proof</li>
								<li><img src={ListIcon}/>Accelerated healing</li>
								<li><img src={ListIcon}/>Soothing & relaxing</li>
								<li><img src={ListIcon}/>Cognition improvement</li>
							</ul>
						</div>
						<div className="info-description">
							<p className="font-weight-bold">
							Step 1: Book your consultation.<br/>
							Step 2: Experience a 30-40 minute session in our energy chamber.<br/>
							Step 3: See improved cellular health and well-being."
							</p>
						</div>
					</div>
					<div className="text-center">
						<NavLink to="/appointment">
							<button className="btn main-button mt-2 mx-2" onClick={(e) => {
							}}>
							<p className="mb-0"><strong>Make Appointment</strong></p>
							</button>
						</NavLink>
						<button className="btn main-button mt-2 mx-2" onClick={(e) => {
						window.open("http://wa.me/60146868574", "_blank");
						}}>
						<p className="mb-0"><strong>Contact Us On WhatsApp</strong></p>
						</button>
					</div>
				</div>
			</div>

			{/* Testimonial */}
			<div className="testimonial">
				<div className="testimonial-container">
					<h2>MediChip Testimonial</h2>
					<div className="testimonial-slider">
						<Swiper
							loop
							// freeMode={true}
							grabCursor={true}
							slidesPerView={1.3}
							// pagination={{
							// 	clickable: true,
							// }}
							// modules={[Pagination]}
							spaceBetween={10}
							speed={1000}
							autoplay={{
								delay: 1000,
								disableOnInteraction: false,
							}}
							breakpoints={{
								475: {
									slidesPerView: 2,
									spaceBetween: 20,
								},
								768: {
									slidesPerView: 3,
									spaceBetween: 30,
								},
								1025: {
									slidesPerView: 4,
									spaceBetween: 40,
								}
							}}
						>
							<SwiperSlide>
								<div className="testimonial-card">
									<img src={Logo} />
									<h4>Datin Linda</h4>
									<div className="rating">
										<img src={Star} />
										<img src={Star} />
										<img src={Star} />
										<img src={Star} />
										<img src={Star} />
									</div>	
									<p>
									The technology works wonders. I noticed significant improvements in my sleep and focus.
									</p>
								</div>
							</SwiperSlide>
							<SwiperSlide>
								<div className="testimonial-card">
									<img src={Logo} />
									<h4>Datin Linda</h4>
									<div className="rating">
										<img src={Star} />
										<img src={Star} />
										<img src={Star} />
										<img src={Star} />
										<img src={Star} />
									</div>	
									<p>
									Medichip Healthcare has transformed my energy levels. I feel healthier than ever!
									</p>
								</div>
							</SwiperSlide>
							<SwiperSlide>
								<div className="testimonial-card">
									<img src={Logo} />
									<h4>Datin Linda</h4>
									<div className="rating">
										<img src={Star} />
										<img src={Star} />
										<img src={Star} />
										<img src={Star} />
										<img src={Star} />
									</div>	
									<p>
									The technology works wonders. I noticed significant improvements in my sleep and focus.
									</p>
								</div>
							</SwiperSlide>
							<SwiperSlide>
								<div className="testimonial-card">
									<img src={Logo} />
									<h4>Datin Linda</h4>
									<div className="rating">
										<img src={Star} />
										<img src={Star} />
										<img src={Star} />
										<img src={Star} />
										<img src={Star} />
									</div>	
									<p>
									Medichip Healthcare has transformed my energy levels. I feel healthier than ever!
									</p>
								</div>
							</SwiperSlide>
							<SwiperSlide>
								<div className="testimonial-card">
									<img src={Logo} />
									<h4>Datin Linda</h4>
									<div className="rating">
										<img src={Star} />
										<img src={Star} />
										<img src={Star} />
										<img src={Star} />
										<img src={Star} />
									</div>	
									<p>
									The technology works wonders. I noticed significant improvements in my sleep and focus.
									</p>
								</div>
							</SwiperSlide>
							<SwiperSlide>
								<div className="testimonial-card">
									<img src={Logo} />
									<h4>Datin Linda</h4>
									<div className="rating">
										<img src={Star} />
										<img src={Star} />
										<img src={Star} />
										<img src={Star} />
										<img src={Star} />
									</div>	
									<p>
									Medichip Healthcare has transformed my energy levels. I feel healthier than ever!
									</p>
								</div>
							</SwiperSlide>
						</Swiper>
					</div>
				<div></div>
				</div>
			</div>

			{/* CTA */}
			<div className="call-to-action">
				<div className="cta-container">
					<h1>Ready to Experience the Future of Health?</h1>
					<h3>Join thousands of satisfied clients and take the first step towards a healthier life.</h3>
					<NavLink to="/appointment">
						<button className="btn main-button mt-2 mx-2" onClick={(e) => {
						}}>
						<p className="mb-0"><strong>Book Your Consultation Now</strong></p>
						</button>
					</NavLink>
					<button className="btn main-button mt-2 mx-2" onClick={(e) => {
                      	window.open("http://wa.me/60146868574", "_blank");
                    }}>
                      <p className="mb-0"><strong>Contact Us On WhatsApp</strong></p>
                    </button>
				</div>
			</div>

		</section>
	);

};

export default Login;