import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import './login.css';

import { useLogin } from '../../hooks/useLogin';

const Login = () => {

	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const {login, error, isLoading} = useLogin();

	const handleSubmit = async (e) => {
		e.preventDefault();
		await login(username, password);
		// console.log("Login");
	};

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<section className="section-preset">
			<div className="container">
				<div className="row">
					<div className="col-12 p-1">
						<h4 className=""><strong>Member Login</strong></h4>
					</div>
				</div>
			</div>
			<div className="container form-page-padding">
				<div className="row form-bg rounded px-3 py-5 my-5">
					<div className="col-12">
						{/* <img className="form-logo mb-3" src={LogoText} alt="logo"></img> */}
						<form className="w-100" onSubmit={handleSubmit}>
							<div className="mb-2">
								<label htmlFor="username">
									<strong className="">Username</strong>
								</label>
								<input
									type="text"
									placeholder='username'
									autoComplete='off'
									name='username'
									className='form-control text-lowercase'
									onChange={(e) => setUsername(e.target.value)}
								/>
							</div>
							<div className="mb-2">
								<label htmlFor="email">
									<strong className="">Password</strong>
								</label>
								<input
									type="password"
									placeholder='password'
									name='password'
									className='form-control rounded-0'
									onChange={(e) => setPassword(e.target.value)}
								/>
							</div>
							<button disabled={isLoading} type="submit" className="btn main-button mt-3 w-100">
								<p className="text-white mb-0"><strong>Login</strong></p>
							</button>
							{error && <div className="error">{error}</div>}
						</form>
						<p className="mt-3 mb-0">Don't have an account?</p>
						<NavLink to="/register" className="btn secondary-button w-100">
							<p className="mb-0"><strong>Register</strong></p>
						</NavLink>
					</div>
				</div>
			</div>
		</section>
	);

};

export default Login;