import { useState } from 'react';

export const useRecharge = () => {

    const [submittedRecharge, setSubmittedRecharge] = useState(null);
    const [selectedRecharge, setSelectedRecharge] = useState(null);

    const submitRecharge = async (userToken, curUsername, rechargeAmount, method) => {

        const response = await fetch('https://api.medichip.health/recharge/create', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userToken}`
            },
            body: JSON.stringify({
                username: curUsername,
                recharge_amount: rechargeAmount,
                payment_method: method
            })
        })
        const json = await response.json();
        if (!response.ok) {
            console.log("Failed.");
        }
        if (response.ok) {
            console.log("Success.", json);
            setSubmittedRecharge(json);
            setSelectedRecharge(json);
        }
    }

    return { submitRecharge, submittedRecharge, selectedRecharge, setSelectedRecharge };
}