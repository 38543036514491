import { useState } from 'react';

export const useBooking = () => {

    const [submittedRecharge, setSubmittedRecharge] = useState(null);
    const [selectedRecharge, setSelectedRecharge] = useState(null);

    const submitBooking = async (userToken, curUsername, selectedUnit, date, timeslot) => {

        // Need to check if user has enough PV - 200

        const response = await fetch('https://api.medichip.health/appointment/create', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userToken}`
            },
            body: JSON.stringify({
                username: curUsername,
                branch: "MedichipHQ",
                day: date,
                time: timeslot,
                unit: selectedUnit,
                status: "Appointment Confirmed",
                points_onhold: 200,
                create_by: "Self-Booking",
                remark: ""
            })
        })
        const json = await response.json();
        if (!response.ok) {
            console.log("Failed.");
        }
        if (response.ok) {
            console.log("Success.", json);
        }
    }

    return { submitBooking };
}