import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import './register.css';

// Hooks
import { useSignup } from '../../hooks/useSignup';

const Register = () => {

	const queryParameters = new URLSearchParams(window.location.search);
	var referrerWallet = queryParameters.get("referral");

	const [username, setUsername] = useState('');
	const [email, setEmail] = useState('');
	const [referrer, setReferrer] = useState('');
	const [password, setPassword] = useState('');

	const { signup, isLoading, error } = useSignup();

	const handleSubmit = async (e) => {
        e.preventDefault();
		if (referrer == '') {
			if (referrerWallet == null || referrerWallet == '') {
				await signup(username, email, password, "default");
			} else {
				await signup(username, email, password, referrerWallet);
			}
		} else {

			await signup(username, email, password, referrer);
		}
    };

	useEffect(() => {
		window.scrollTo(0, 0);
		console.log("Referrer Wallet from URL:", referrerWallet);
	}, []);

	return (
		<section className="section-preset">
			<div className="container">
				<div className="row">
					<div className="col-12 p-1">
						<h4 className=""><strong>Member Register</strong></h4>
					</div>
				</div>
			</div>
			<div className="container form-page-padding">
				<div className="row form-bg rounded px-3 py-5 my-5">
					<div className="col-12">
						<form className="w-100" onSubmit={handleSubmit}>
							<div className="mb-2">
								<label htmlFor="email">
									<strong className="">Email</strong>
								</label>
								<input
									type="text"
									placeholder='email'
									autoComplete='off'
									name='email'
									className='form-control text-lowercase'
									onChange={(e) => setEmail(e.target.value)}
								/>
							</div>
							<div className="mb-2">
								<label htmlFor="username">
									<strong className="">Username</strong>
								</label>
								<input
									type="text"
									placeholder='username'
									autoComplete='off'
									name='username'
									className='form-control text-lowercase'
									onChange={(e) => setUsername(e.target.value)}
								/>
							</div>
							<div className="mb-2">
								<label htmlFor="password">
									<strong className="">Password</strong>
								</label>
								<input
									type="password"
									placeholder='password'
									name='password'
									className='form-control rounded-0'
									onChange={(e) => setPassword(e.target.value)}
								/>
							</div>
							<div className="mb-2">
								<label htmlFor="referrer">
									<strong className="">Referrer (Optional)</strong>
								</label>
								<input
									type="text"
									placeholder='referrer'
									name='referrer'
									className='form-control'
									defaultValue={referrerWallet}
									onChange={(e) => setReferrer(e.target.value)}
								/>
							</div>
							<button disabled={isLoading} type="submit" className="btn main-button mt-3 w-100">
								<p className="text-white mb-0"><strong>Register</strong></p>
							</button>
							{error && <div className="error">{error}</div>}
						</form>
						<p className="mt-3 mb-0">Already registered?</p>
						<NavLink to="/login" className="btn secondary-button w-100">
							<p className="mb-0"><strong>Login</strong></p>
						</NavLink>
					</div>
				</div>
			</div>
		</section>
	);

};

export default Register;