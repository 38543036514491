import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import MiddleEllipsis from "react-middle-ellipsis";

// Hooks
import { useAuthContext } from '../../hooks/useAuthContext';

const Referral = () => {

	const [copied, setCopied] = useState(false);
	const [copyText, setCopyText] = useState("");
	const copyReferral = () => {    
	  setCopied(true);
	  setCopyText("Copied");
	  setTimeout(() => setCopied(false), 3000);
	}

	const { loggedInUser } = useAuthContext();

	const [userData, setUserData] = useState(null);
	const fetchUserData = async () => {
		const response = await fetch('https://api.medichip.health/user/'+loggedInUser.user.username, {
			method: 'GET',
			headers: {
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${loggedInUser.token}`
			}
		});
		const json = await response.json();
		if (response.ok) {
			if (json.length === 1) {
				// Expect only 1 result
				console.log(json[0]);
				setUserData(json[0]);
			} else {
				console.log("Invalid user data: More than 1 result.");
			}
		}
	};

	const [downlinesData, setDownlinesData] = useState(null);
	const fetchDownlinesData = async () => {
		// const response = await fetch('https://api.medichip.health/user/downline/default', {
		const response = await fetch('https://api.medichip.health/user/downline/'+loggedInUser.user.username, {
			method: 'GET',
			headers: {
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${loggedInUser.token}`
			}
		});
		const json = await response.json();
		if (response.ok) {
			console.log(json);
			setDownlinesData(json);
		}
	};

	useEffect(() => {
		window.scrollTo(0, 0);
		if (loggedInUser) {
			fetchUserData();
			fetchDownlinesData();
		}
	}, []);

	return (
		<section className="section-preset">
			<div className="container">
				<div className="row">
					<div className="col-12 p-1">
						<h4 className=""><strong>Referral Program</strong></h4>
						<p className="">Invite your friends to register with Medichip and earn additional rewards and benefits as they top-up credit.</p>
					</div>
				</div>

				<div className="row">
					<div className="col-12 p-1">
						<div className="data-box">
							<p className="mb-0"><strong>My Referral Link:</strong></p>
							<MiddleEllipsis><span className="mt-1 mb-0" style={{fontSize:"14px"}}>https://medichip.health/register?referral={loggedInUser.user.username}</span></MiddleEllipsis>
							<CopyToClipboard 
								text={"https://medichip.health/register?referral="+loggedInUser.user.username}
								onCopy={() => copyReferral()}>
								<button className="btn setting-button mt-2" onClick={(e) => {
								}}>
									{!copied ? (
										<p className="mb-0"><i className="fa-sharp fa-solid fa-copy mr-2"></i><strong>Copy</strong></p>
									):(
										<p className="mb-0"><i className="fa-sharp fa-solid fa-copy mr-2"></i><strong>{copyText}</strong></p>
									)}
								</button>
							</CopyToClipboard>
						</div>
					</div>
				</div>

				<div className="row mt-5">
					<div className="col-12 p-1">
						<h6 className=""><strong>My Team Summary</strong></h6>
						<div className="row px-3">
							<div className="col-sm-6 mt-2 px-1">
								<div className="data-box">
									<p className="mb-0"><strong>Total Team Members:</strong></p>
									<p className="mb-0"><strong>0</strong></p>
								</div>
							</div>
							<div className="col-sm-6 mt-2 px-1">
								<div className="data-box">
									<p className="mb-0"><strong>My Team Total Top Up:</strong></p>
									<p className="mb-0"><strong><span className="profit-green">{parseFloat(0).toFixed(2)}</span> PV</strong></p>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="row mt-5">
					<div className="col-12 p-1">
						<h6 className=""><strong>My Direct Referrals</strong></h6>
						{downlinesData && downlinesData.length > 0 ? (
							<>
								{downlinesData && downlinesData.map((teamUser, index) => (
									<div className="data-box mt-2" key={index}>
										<p className="mb-0"><strong>Username:</strong> {teamUser.username}</p>
										<p className="mb-0"><strong>Rank:</strong> M{teamUser.rank}</p>
										<p className="mb-0"><strong>Team Total Top Up:</strong><span className="profit-green"> {parseFloat(0).toFixed(2)}</span> PV</p>
									</div>
								))}

							</>
						):(
							<div className="data-box mt-2">
								<p className="mb-0">No direct referral is found for this account.</p>
							</div>
						)}
					</div>
				</div>
			</div>
		</section>
	);

};

export default Referral;