import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

const Order = () => {

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<section className="section-preset">
			<div className="container">
				<div className="row">
					<div className="col-12 p-1">
						<h4 className=""><strong>My Orders</strong></h4>
					</div>
				</div>

				<div className="row">
					<div className="col-12 mt-2 px-2">
						{/* <h6 className=""><strong>Order History</strong></h6> */}
						{/* {userDirectReferrals && userDirectReferrals.length > 0 && userData && userData.total_deposit >= 50 ? ( */}
							<div className="table-container details-table">
								<table className="table borderless">
									<thead>
										<tr>
											<th scope="col" className="py-2"><p className="mb-0" style={{fontSize:"14px"}}>Order ID</p></th>
											<th scope="col" className="py-2"><p className="mb-0" style={{fontSize:"14px"}}>Order Details</p></th>
											{/* <th scope="col" className="py-2"><p className="mb-0" style={{fontSize:"14px"}}>Item</p></th>
											<th scope="col" className="py-2"><p className="mb-0" style={{fontSize:"14px"}}>Status</p></th> */}
										</tr>
									</thead>
									<tbody>
										<tr>
										{/* <tr key={index}> */}
											{/* <td className="py-2"><p className="mb-0" style={{fontSize:"14px"}}>#001</p></td>
											<td className="py-2">
												<p className="mb-0" style={{fontSize:"14px"}}><strong>Time: </strong>2024-9-22 15:32H</p>
												<p className="mb-0" style={{fontSize:"14px"}}><strong>Item: </strong>Medichip Wristband</p>
												<p className="mb-0" style={{fontSize:"14px"}}><strong>Quantity: </strong>1</p>
												<p className="mb-0" style={{fontSize:"14px"}}><strong>PV Amount: </strong>200</p>
												<p className="mb-0" style={{fontSize:"14px"}}><strong>Status: <span className="pending">Processing</span></strong></p>
											</td> */}
											{/* <td className="py-2"><p className="mb-0" style={{fontSize:"14px"}}>1</p></td>
											<td className="py-2"><p className="mb-0" style={{fontSize:"14px"}}>1</p></td> */}
											{/* <td className="py-2"><p className="text-white mb-0" style={{fontSize:"14px"}}>{parseFloat(teamUser.total_deposit).toFixed(2)}</p></td>
											<td className="py-2"><p className="text-white mb-0" style={{fontSize:"14px"}}>{teamUser.total_team_sales > 0 ? parseFloat(teamUser.total_team_sales).toFixed(2) : 0}</p></td>
											<td className="py-2"><p className="text-white mb-0" style={{fontSize:"14px"}}>{teamUser.referral_count}</p></td> */}
										</tr>

									</tbody>
								</table>
							</div>

							{/* <div className="data-box">
								<div className="row">
									<div className="col-12">
										<p className="text-gray mb-0">此账户还未有推荐。</p>
									</div>
								</div>
							</div> */}

						
					</div>
				</div>

				<div className="row mt-3">
					<div className="col-12 p-1">
						<div className="data-box">
							<p className="mt-1 mb-0" style={{fontSize:"14px"}}>No order record is found for this user.</p>
							<button disabled className="btn setting-button mt-2" onClick={(e) => {
								// window.location.href = "/shop";
							}}>
								<p className="mb-0"><strong>Shop Now</strong></p>
							</button>
						</div>
					</div>
				</div>

			</div>
		</section>
	);

};

export default Order;